<template>
  <div id="mian">
    <div>
      <div class="con_from">
        <div class="infoBox">
          <div class="infoBoxTitel">结算</div>
          <div class="paymentInfo">
            <div class="item">
              <div class="lable">可用余额：</div>
              <div class="content">
                <span>{{ (fromData.settleAmount / 100) | formatMoney }}</span>              
                <img class="newPost" :class="rotateRun==true ? 'rotateRun' : ''" @click="refreshBtn(0)" src="../../../../assets/Refresh.png" alt=""/>
              </div>
            </div>
            <div class="item">
              <div class="lable">银行账户：</div>
              <div class="content">{{ fromData.accountNo }}</div>
            </div>
            <!-- <div class="line"></div> -->
            <div class="item">
              <div class="lable">账户名：</div>
              <div class="content">{{ fromData.accountName }}</div>
            </div>
            <div class="item">
              <div class="lable"><span>*</span>结算金额：</div>
              <div class="content">
                <Input
                  placeholder="请输入金额"
                  v-model="postData.amount"
                  οnkeyup="value=value.replace(/[^\d]/g,'')"
                  @input="inputLength"
                  style="width:235px"
                ></Input>
                <div
                  class="rollOut"
                  @click="rollOutBtn()"
                >全部结算</div>
              </div>
            </div>
            <div class="item" style="margin-bottom:4px;">
              <div class="lable"><span>*</span>安全密码：</div>
              <div class="content">
                <Input
                  placeholder="请输入安全密码"
                  type="password"
                  password
                  v-model="postData.securityPassword"
                  style="width:235px"
                  maxlength="6"
                ></Input>
                <div
                  class="rollOut"
                  @click="handlEditPassword()"
                >忘记密码?</div>
              </div>
            </div>
            <div class="item tipsTitle" v-if="errorMessage != ''">{{ errorMessage }}</div>
            <div class="item" style="height:auto;">
              <div class="lable"></div>
              <div class="content">
                <Button style="margin-left: 72.5px;"
                  class="contentButton"
                  :disabled="buttonFlag"
                  @click="affirmDeposit()"
                >结 算</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal title="找回安全密码" v-model="resetPassword" :close-on-click-modal="false"  width="490px" @on-cancel="resetPassword = false">
        <ul
          class="overAccount_ul"
          style="margin-bottom:32px"
        >
          <li class="ul_left">
            <div
              class="overAccount_ul_title"
              style="color:#666666"
            >绑定手机号</div>
            <Input
              v-model="phone"
              maxlength="11"
              placeholder="输入手机号"
              style="width:235px"
              disabled
            ></Input>
            <span
              class="getCode"
              @click="getOldCode"
            >{{timeOut==0?'获取验证码':`倒计时${timeOut}s`}}</span>
            <div
              class="overAccount_ul_title"
              style="color:#666666"
            ><i>*</i>验证码</div>
            <Input
              v-model="resetPasswordFrom.validateCode"
              maxlength="6"
              placeholder="输入短信验证码"
              style="width: 320px;"
            ></Input>
            <div
              class="overAccount_ul_title"
              style="color:#666666"
            ><i>*</i>登录密码</div>
            <Input
              v-model="resetPasswordFrom.loginPassword"
              type="password"
              password
              placeholder="输入登录密码"
              style="width: 320px;"
            ></Input>
            <div
              class="overAccount_ul_title"
              style="color:#666666"
            ><i>*</i>设置安全密码</div>
            <Input
              v-model="resetPasswordFrom.newSecurityPassword"
              type="password"
              password
              placeholder="输入安全密码(6位数字)"
              style="width: 320px;"
              maxlength="6"
            ></Input>
            <div
              class="overAccount_ul_title"
              style="color:#666666"
            ><i>*</i>确认安全密码</div>
            <Input
              v-model="resetPasswordFrom.reNewSecurityPassword"
              type="password"
              password
              placeholder="输入确认安全密码(6位数字)"
              style="width: 320px;"
              maxlength="6"
            ></Input>
          </li>
        </ul>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <Button
            class="exitBt"
            @click="resetPassword = false"
          >取 消</Button>
          <Button
            class="addBt"
            @click="settingPwd"
            style="width:60px;height:32px"
          >确 定</Button>
        </span>
      </Modal>
    </div>
  </div>
</template>
<script>
import { getSecurity, getValidatecode } from '@/api/common.js'
import { getAltwithdrawSettlement ,getSetTraDataHome , getMerchantsDetail} from "@/api/basic/balance.js";
import { resetPassword } from '@/api/management/index.js'
import md5 from 'js-md5';
export default {
  data() {
    return {
      mchId: "",
      fromData: {},
      postData: {
        allocMchId:"",
        securityPassword: "",// 安全密码
        amount: "",// 金额
        // remark: "",// 备注
      },
      timeOut: 0,
      codeShow: null,
      phone: "",//加密手机号
      resetPassword: false,//忘记安全密码展示
      resetPasswordFrom: {
        boundPhone: '',
        validateCode: '',
        loginPassword: '',
        newSecurityPassword: '',
        reNewSecurityPassword: ''
      },// 忘记密码      
      buttonFlag: false,
      errorMessage:"",
      rotateRun: false,
    };
  },
  created() {
    this.mchId = JSON.parse(sessionStorage.getItem("userInfo")).mchId;
    this.fromData = this.$route.query;
    this.getUserInfo();
    console.log(this.fromData)
  },
  methods: {
    affirmDeposit() {
      let data = JSON.parse(JSON.stringify(this.postData));
      if (!data.amount || data.amount == 0) {
        this.$Message.error("请输入金额,且大于0");
        return false;
      }
      if (!data.securityPassword) {
        this.$Message.error("请输入安全密码");
        return false;
      }
      let availableAmount = this.fromData.settleAmount / 100
      if (availableAmount < data.amount) {
        this.$Message.error("结算金额不能大于可用余额");
        return false;
      }

      data.allocMchId = this.fromData.allocMchId;
      data.amount = data.amount * 100;
      data.securityPassword = md5(data.securityPassword);

      this.buttonFlag = true;
      getAltwithdrawSettlement(data).then((res) => {
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
        if(res){
          if (res.resultData == null) { 
            this.errorMessage = "";          
            this.$message({
              dangerouslyUseHTMLString: true,
              message: '<p style="line-height:24px">操作成功</p ><n/><p style="line-height:24px">可在【余额账户管理】-【结算查询】查看结果</p >',
              type: 'success',
              customClass: "messSuccess",
              showClose: true,
            });
            this.postData= {
              allocMchId:"",
              securityPassword: "",// 安全密码
              amount: "",// 金额
            };
          }else{
            this.errorMessage = res.resultData.msg;
            console.log(res.resultData.code)
          }           
        }
        this.refreshBtn(1);
      })
    },
    rollOutBtn() {
      this.postData.amount = this.fromData.settleAmount / 100;
    },
    //刷新
    refreshBtn(val){
      if(this.fromData.thirdAccountId != undefined && this.fromData.thirdAccountId != null && this.fromData.thirdAccountId != ""){
        if (val == 0) {
            this.rotateRun = true
        }
        getMerchantsDetail(this.fromData.thirdAccountId).then((res) => {
          console.log(res)
          this.fromData = res.resultData;
          if(val == 0){
            this.$Message.success("刷新成功");
            this.rotateRun = false
          } 
        })
      }else{
        if (val == 0) {
            this.rotateRun = true
        }
        getSetTraDataHome(this.mchId).then((res)=>{
          if(res){      
            this.fromData = res.resultData.balanceAccount;
            if(val == 0){
              this.$Message.success("刷新成功");
              this.rotateRun = false
            }          
          }
        })
      }     
    },
    // 获取个人信息
    getUserInfo() {
      getSecurity().then(res => {
        if (res) {
          this.phone = res.resultData.phoneNumber.replace(/^(\d{3})\d+(\d{4})$/, '$1****$2');
          console.log(this.phone)
        }
      })
    },    
    // 输入金额位数提示    
    inputLength(e) {
      if (!(/^(\d?)+(\.\d{0,2})?$/.test(e))) {
        e = e.substring(0, e.length - 1);
        this.$Message.error("请输入数字，且只能保留小数点后两位");
      }
      this.postData.amount = e;
    },
    //忘记密码
    handlEditPassword() {
      this.resetPasswordFrom = {
        boundPhone: this.phone,
        validateCode: '',
        loginPassword: '',
        newSecurityPassword: '',
        reNewSecurityPassword: ''
      }
      clearInterval(this.codeShow)
      this.timeOut = 0
      this.resetPassword = true;
    },
    // 获取原验证码
    getOldCode() {
      if (this.timeOut != 0) {
        return;
      }
      this.timeOut = 60
      getValidatecode().then(res => {
        if (res) {
          this.codeShow = setInterval(() => {
            if (this.timeOut == 0) {
              clearInterval(this.codeShow)
            } else {
              this.timeOut--
            }
          }, 1000)
        } else {
          clearInterval(this.codeShow)
          this.timeOut = 0
        }
      })
    },
    // 重置密码
    settingPwd() {
      let data = { ...this.resetPasswordFrom }
      if (!data.validateCode) {
        this.$Message.error('请输入验证码')
        return
      }
      if (!data.loginPassword) {
        this.$Message.error('请输入登录密码')
        return
      }
      if (!data.newSecurityPassword) {
        this.$Message.error('请输入新安全密码')
        return
      }
      if (!data.reNewSecurityPassword) {
        this.$Message.error('请输入确认新安全密码')
        return
      }
      if(data.newSecurityPassword.length<6 || data.reNewSecurityPassword.length<6){
        this.$Message.error('安全密码必须是六位数字')
        return
      }
      let regNumber = /^[0-9]*$/;
      if(!regNumber.test(data.newSecurityPassword) || !regNumber.test(data.reNewSecurityPassword)){
          this.$Message.error('安全密码必须是六位数字')
          return
      }
      if (data.reNewSecurityPassword !== data.newSecurityPassword) {
        this.$Message.error('新安全密码输入不一致')
        return
      }
      data.loginPassword = md5(data.loginPassword)
      data.newSecurityPassword = md5(data.newSecurityPassword)
      delete data.reNewSecurityPassword
      resetPassword(data).then(res => {
        if (res) {
          this.getUserInfo()
          this.$Message.success('设置成功')
          this.errorMessage = "";
          this.resetPassword = false;
        }
      })
    },
  },
};
</script>

<style scoped>
#mian {
  padding: 20px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}
.con_from {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.infoBox {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 20px;
}
.infoBox .paymentInfo {
  width: 500px;
    margin: auto;
}

.infoBox .infoBoxTitel {
  /* font-size: 20px;
  text-align: left; */
  margin-bottom: 20px;
  font-size: 16px;
    font-family: PingFang SC;
    font-weight: 600;
    color: rgba(153, 153, 153, 1);
    text-align: left;
}
.infoBox .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 40px;
  height: 40px;
  margin-bottom: 18px;
}

.infoBox .item .lable {
  width: 120px;
  text-align: right;
}
.infoBox .item .lable span {
  color: var(--themeColor);
  margin-right: 4px;
}
.infoBox .item .content {
  width: calc(100% - 180px);
  display: flex;
  align-items: center;
}
.infoBox .item .content img{
  margin-left: 20px;
}
.infoBox .item .content .rollOut {
  margin-left: 20px;
  color: var(--togoBtn);
  cursor: pointer;
}

.infoBox .item .content .contentButton {
  width: 100px;
  /* margin:0px 15px; */
  background: var(--themeColor);
  border-color: var(--themeColor);
  width: 90px;
  height: 36px;
  padding: 0;
  color: #ffffff;
  line-height: 36px;
  margin-top: 20px;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul .ul_left {
  width: 320px;
  margin: 0 auto;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #66666666;
  text-align: left;
  margin-top: 16px;
}
.getCode {
  display: inline-block;
  width: 70px;
  margin-left: 15px;
  color: var(--themeColor);
  cursor: pointer;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.tipsTitle{
  text-align: center;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: auto;
    line-height: initial;
    color: red;
    font-size: 12px;
    margin-bottom: 0px !important;
}
@keyframes rotate {
    0%{-webkit-transform:rotate(0deg);}
    25%{-webkit-transform:rotate(90deg);}
    50%{-webkit-transform:rotate(180deg);}
    75%{-webkit-transform:rotate(270deg);}
    100%{-webkit-transform:rotate(360deg);}
}
.rotateRun { animation: rotate 1s linear infinite; }
</style>



